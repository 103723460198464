<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="药品分类">
                <el-select v-model="searchForm.categoryId" placeholder="请选择" size="small" style="width: 150px"
                  @change="Search">
                  <el-option :label="item.categoryName" :value="item.categoryId" v-for="(item, index) in categoryData"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="药品名称/别名"
                  @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet"
                  :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="warning" size="mini" class="el-icon-download">导出数据</el-button>
                </download-excel>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }"
          height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="mzDrug.drugName" label="药品名称"> </el-table-column>
          <el-table-column prop="mzDrug.drugAlias" label="别名" align="center"> </el-table-column>
          <el-table-column prop="mzDrug.category.categoryName" label="药品分类" align="center"> </el-table-column>
          <el-table-column prop="mzDrug.specification" label="规格" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.mzDrug.specification">
                {{ scope.row.mzDrug.specification }}/{{ scope.row.mzDrug.specificationUnit }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="mzDrug.productFactory" label="生产厂家" align="center"></el-table-column>
          <el-table-column prop="inventoryNumber" label="库存" align="center">
            <template slot-scope="scope">
              {{ scope.row.inventoryNumber ? scope.row.inventoryNumber : 0
              }}（{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.mzDrug.specificationUnit }}）
            </template>
          </el-table-column>
          <el-table-column prop="inventoryMix" label="库存阈值" align="center">
            <template slot-scope="scope">
              {{ scope.row.inventoryMix ? scope.row.inventoryMix : 0
              }}（{{ scope.row.isSeparate ? scope.row.separateNumberUnit : scope.row.mzDrug.specificationUnit }}）
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="primary optionBtn" @click="returnBtn(scope.row)" v-if="scope.row.inventoryNumber>0">退库</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    
    <el-dialog title="退库" :visible.sync="returnDialog" width="30%">
      <el-form class="dialog-form" :model="returnForm" ref="returnForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="退库数量" prop="putOutNumber">
          <el-input-number v-model="returnForm.putOutNumber" size="small" controls-position="right" :precision="0"
            :step="1" :max="putOutNumber" :min="1" class="leftInput" style="width: 150px;"></el-input-number>
        </el-form-item>
        <el-form-item label="退库原因" prop="putOut">
          <el-select v-model="returnForm.putOut" placeholder="请选择" clearable style="width:100%">
            <el-option :label="item.adviceContent" :value="item.adviceContent" v-for="item in returnReason"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseDialog('returnForm')">取 消</el-button>
        <el-button type="primary" @click="saveData('returnForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  inject: ['reload'],
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      UserId: null,
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      categoryData: [],
      searchForm: {
        keyWord: '',
        categoryId: 0,
      },
      title: "库存预警",
      DataCount: 0,
      json_fields: {
        药品名称: {
          filed: "mzDrug",
          callback: val => {
            return val.mzDrug.drugName
          },
        },
        药品分类: {
          filed: "mzDrug",
          callback: val => {
            return val.mzDrug.category.categoryName
          },
        },
        规格: {
          filed: "mzDrug",
          callback: val => {
            return val.mzDrug.specification + '/'+ val.mzDrug.specificationUnit
          },
        },
        生产厂家: {
          filed: "mzDrug",
          callback: val => {
            return val.mzDrug.productFactory
          },
        },
        库存: {
          filed: "inventoryNumber",
          callback: val => {
            let specification=val.isSeparate?val.separateNumberUnit:val.mzDrug.specificationUnit
            return val.inventoryNumber + '/（'+ specification + '）'
          },
        },
        库存阈值: {
          filed: "inventoryMix",
          callback: val => {
            let specification=val.isSeparate?val.separateNumberUnit:val.mzDrug.specificationUnit
            return val.inventoryMix + '/（'+ specification + '）'
          },
        },
      },
      fileList: [],
      returnDialog: false,
      formLabelWidth: '90px',
      returnForm: {
        mzDrugInId: '',
        putOutNumber: null,
        putOut: ""
      },
      returnReason: [],
      putOutNumber: 0,
      rules: {
        putOutNumber: [{ required: true, message: '请输入退库数量', trigger: 'blur' }],
        putOutReason: [{ required: true, message: "请选择退库原因", trigger: "change" }],
      },
    }
  },
  mounted () {
    this.getList()
    this.LoadCategory()
    this.getReturnReason()
  },
  methods: {
    getReturnReason () {
      var _this = this
      _this.OutpatientDomain.RefundRepertoryList('', 1,
        function (data) {
          _this.returnReason = data.data.results
        },
        function (err) {
          console.log(err)
        }
      )
    },
    saveData (formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(_this.returnForm)
          const loading = this.$loading({
            lock: true,
            background: 'rgba(0, 0, 0, 0.5)'
          })
          _this.OutpatientDomain.DrugOrgOut(_this.returnForm,
            function (data) {
              loading.close()
              _this.$message({
                message: '操作成功',
                type: 'success',
              })
              _this.reload()

            },
            function (error) {
              loading.close()
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    CloseDialog (formName) {
      this.returnDialog = false
      this.$refs[formName].resetFields()
    },
    returnBtn (row) {
      this.returnDialog = true
      this.putOutNumber = row.inventoryNumber
      this.returnForm = {
        mzDrugInId: row.mzDrugIns[0].id,
        putOutNumber: row.inventoryNumber,
        putOutReason: ""
      }
    },
    async fetchData () {
      var _this = this
      return await _this.getAllData()
    },

    getAllData () {
      var _this = this
      return new Promise((resolve, reject) => {
        var item = _this.searchForm
        _this.OutpatientDomain.DrugOrgInWarn(item.keyWord, item.categoryId, this.pageIndex, _this.DataCount,
          function (data) {
            resolve(data.data.results)
          },
          function (err) {
            resolve('')
            console.log(err)
          })
      })

    },
    LoadCategory () {
      var _this = this
      _this.OutpatientDomain.GetOutpatientCategories(
        function (data) {
          _this.categoryData = data.data.categories
          _this.categoryData.unshift({ categoryId: 0, categoryName: '全部' })
        },
        function (err) {
          console.log(err)
        }
      )
    },
    changePage (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList () {
      var _this = this
      _this.tableData = []
      var item = _this.searchForm
      _this.OutpatientDomain.DrugOrgInWarn(item.keyWord, item.categoryId, this.pageIndex,null,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
          if (!item.keyWord) {
            _this.DataCount = data.data.dataTotal
          }
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search (event) {
      this.pageIndex = 1
      this.getList()
    },
    // 导入数据
    handleExcel (file, fileList) {
      console.log(file.raw)
      let formData = new FormData() //声明一个FormDate对象
      formData.append("formFile", file.raw) //把文件信息放入对象中

    },
    //模板下载
    downloadTemplate () {
      let a = document.createElement("a")
      a.href = "./drug_import_template_with_branch.xls"
      a.download = "药品模板.xlsx"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}

::v-deep .el-table {
  color: red !important;
}
</style>
